<template>
  <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
    <v-dialog :value="value" transition="slide-y-transition" class="elevation-0" persistent max-width="450px">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title class="grey lighten-4">
          {{ $t("hr.training.request_approval_process") }}
          <v-spacer />
          <v-btn depressed icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="trainingRequestProcess.note"
                :label="$t('global.description')"
                color="tertiary"
                :counter="512"
              />
            </v-col>
            <v-col cols="12">
              <v-radio-group row mandatory v-model="trainingRequestProcess.approved">
                <v-radio color="primary" :value="false" :label="$t('hr.training.denied')" />
                <v-radio color="green" :value="true" :label="$t('hr.training.approved')" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-3">
          <v-spacer />
          <v-btn color="primary" depressed @click="handleSubmit(approveRequest)" :disabled="loading">
            {{ $t("buttons.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
  export default {
    name: "RequestApprovalDialog",
    props: {
      value: Boolean,
      requestId: String
    },
    data: () => ({
      loading: false,
      trainingRequestProcess: {
        approved: false,
        note: null,
        eventDateTime: null
      }
    }),
    watch: {
      value(value) {
        if (value) {
          this.trainingRequestProcess.eventDateTime = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
        }
      }
    },
    methods: {
      resetTrainingRequestProcess() {
        this.trainingRequestProcess.note = null;
        this.trainingRequestProcess.approved = false;
        this.trainingRequestProcess.eventDateTime = null;
      },
      closeDialog() {
        this.$refs.observer.reset();
        this.resetTrainingRequestProcess();
        this.$emit("input", false);
      },
      approveRequest() {
        this.loading = true;
        this.$api.trainingRequestService
          .approve(this.requestId, this.trainingRequestProcess)
          .then(response => {
            if (response.status === 200) {
              this.closeDialog();
              this.$emit("success");
            }
          })
          .catch(error => {
            this.$helpers.showNotification(error.message);
          })
          .finally(() => (this.loading = false));
      }
    }
  };
</script>
